<template>
  <div id="box">
    <div class="page">
      <div class="top-view">
        <img class="top-view-img" src="../assets/static/icon/234.png">
      </div>
      <div class="mian-view">
        <div class="mian-view-title">网站说明</div>
        <div class="mian-view-tip">
          <p>本页面是永久防失联页面</p>
          <p>请将本页面添加到浏览器书签，方便下次访问。</p>
          <p>微信/QQ软件内打开的用户，请点击右上角三个点跳转到任意浏览器即可打开网站。</p>
        </div>
        <div class="mian-view-download">
          <img src="../assets/static/icon/2.png">
        </div>
      </div>
      <div class="floor">
        <div class="floor-item">
          <div class="floor-item-title">
            <div class="floor-item-title-bg">
              <span style="width: 100%;">1.浏览器保存书签教程</span>
            </div>
          </div>
          <div class="floor-item-body">
            <p>安卓QQ浏览器：打开网页→点击底部中间“三”状态栏→收藏网址→返回浏览器主页→我的→书签收藏→完成</p>
            <p>苹果手机浏览器：打开网页→点击底部中间状态栏→添加到主屏幕→网址信息保持默认→右上角添加→返回手机桌面→完成</p>
            <p>电脑QQ浏览器：打开网页→鼠标右键网页内容→添加书签→完成</p>
            <p>本网页是永久防失联的，请按照以上步骤将本页面收藏到你的浏览器书签内，可以长期稳定进入。</p>
          </div>
        </div>
        <div class="floor-item">
          <div class="floor-item-title">
            <div class="floor-item-title-bg">
              <span style="width: 100%;">2.网站打不开解决办法</span>
            </div>
          </div>
          <div class="floor-item-body">
            <p>
              本平台长期稳定运行，不存在无法打开的情况，如果你是遇到网站/APP进不去的情况，出现此问题的常见情况，基本是你所在的地区性网络干扰导致无法正常进入，并不是代表所有人都进不去你网站，所以，请不必担心，建议采取以下几种方法进入。
            </p>
            <p>方法一：开关手机的飞行模式或者尝试重启手机；</p>
            <p>方法二：切换手机数据流量网络或者尝试开启WIFI网络；</p>
            <p>方法三：部分地区由于网络原因导致，如果以上两个方法均无法正常打开网址，可以尝试前往百度搜索使用【精灵ip】或者【爱加速】等网络加速器，下载后切换IP基本就可以正常进入网站/APP了。</p>
          </div>
        </div>
        <div class="floor-item">
          <div class="floor-item-title">
            <div class="floor-item-title-bg">
              <span style="width: 100%;">3.网站页面提示域名升级</span>
            </div>
          </div>
          <div class="floor-item-body">
            <p>如果你是本站站长/合伙人，打开网站显示网址有误或网址已升级，你可以扫你自己的推广海报重新进入，或找个其他能打开的站长海报图，进入后登录你自己的账号，在会员中心-推广海报内重新生成你自己的海报图即可正常进入。</p>
            <p>如果你是本站用户，打开网站显示网址有误或网址已升级，请与你上级站长/合伙人取得联系，获取最新网址信息或推广海报。</p>
            <p>如果你打开APP时显示APP过期或异常提醒，请与你上级站长/合伙人取得联系，让上级站长生成最新的APP，重新下载即可正常使用。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import { MessageBox } from "element-ui";
export default {
  created() {
    let that = this;
    var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
    var isWeixin = ua.indexOf("micromessenger") != -1; //判断是否
    if (isWeixin) {
      // MessageBox.confirm("请在其他浏览器中打开?", "提示", { type: "success" })
      // MessageBox.confirm('请通过其他浏览器打开')

      return false;
    } else {
      this.$router.push({ path: '/' })
      return false;

    }
  },
};
</script>
<style scoped>
#box{
  width: 100vw;
}
.page {
  width: 100vw;
  margin: 0 auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.top-view {
  width: 100%;
  color: #fff;
  font-size: 14px;
  position: relative;
}

.top-view .top-view-img {
  width: 100%;
}

.top-view .top-view-body {
  position: absolute;
  left: 5%;
  top: 10%;
}
.top-view .top-view-body p{
  color: #fff;
}

.top-view .top-view-body .icon-safari {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin: 0 .5em;
}

.mian-view {
  width: 95%;
  /* height: 400px; */
  /* background-color: #f424ea; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.mian-view-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #101010;
  /* font-weight: 600; */
}

.mian-view-tip {
  font-size: 14px;
  color: #666666;
  width: 49.5%;
  font-weight: 400;
  letter-spacing: 0px;

  line-height: 20px;
  /* text-indent:28px */
}

.mian-view-download {
  width: 49.5%;
}

.mian-view-download img {
  width: 100%;
}

.floor {
  width: 95%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  padding-bottom: 20px;
}

.floor-item {
  width: 32%;
  border-radius: 8px;
  background: #FFF6F6;
  /* border: 1px solid rgba(151, 151, 151, 1); */
  height: 100%;
  position: relative;
}

.floor-item .floor-item-title {
  position: absolute;
  top: -12px;

  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background: rgba(239, 244, 255, 1);
  width: 80%;
  overflow: hidden;


}

.floor-item .floor-item-title .floor-item-title-bg {
  width: 100%;
  height: 25px;
  line-height: 25px;
  padding: 0 5px;
  background: #F9D3D2;
  font-size: 80%;
  color: #E20200;
  text-align: center;
  font-size: 12px;
}

.scale-8 {
  display: inline-block;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  margin-left: -10px;
}

.scale-7 {
  display: inline-block;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);

}

.floor-item-body {
  padding: 10px 5px;
  margin-top: 10px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #555;
  text-align: left;
  font-size: 12px;
  text-indent: 24px
}

.time {
  width: 150px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
  margin-top: 10px;
  display: none;
}

@media (max-width: 700px) {
  .page {
    width: 100%;
  }

  .floor {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .floor-item {
    width: 90%;
    margin-bottom: 20px;
  }
}
</style>